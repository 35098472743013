export const cardData = {
  assessment: {
    description:
      "To answer the question, read the prompt carefully and consider the concept being tested. Choose the best answer from the options provided, then click Submit Answer to check if your selection is correct. After submitting, review the feedback to understand the reasoning behind the correct answer and enhance your understanding.",
    tags: [],
  },
  knowledge: {
    description:
      "To fully benefit from this informational card, read the content carefully and consider the key concepts presented. Reflect on how this information expands your understanding, and think about how you can apply these insights in relevant contexts.",
    tags: [],
  },
  Flashcard: {
    description:
      "To effectively use this flashcard, first read the question or prompt carefully and try to recall the answer from memory. Once you've formulated your response, click Show Answer to reveal the correct information. Compare it with your own answer to assess your understanding, and use this opportunity to reinforce your learning or identify areas for further review.",
    tags: [],
  },
};
