import React, { useEffect, useState } from "react";
import { videoService } from "../../services/video.service.ts";
import Spinner from "../Spinner/Spinner";
import ItemCard from "../ItemCard/ItemCard.js";
import CardPreview from "../CardPreview/CardPreview.js";
import "./styles.scss";

const Playlist = ({
  type,
  video,
  navigateToVideo,
  constVideos,
  playlistTitle,
}) => {
  /*
    Component that shows a list of videos to be watched next. Used by the FeedCard (/feed, /video/uuid, /playlist/uuid).
  */

  const [playlistItems, setPlaylistItems] = useState([]);
  const [title, setTitle] = useState("");
  const [showCount, setShowCount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [headerEnd, setHeaderEnd] = useState(<div></div>);

  useEffect(() => {
    if (type === "playlist") {
      setPlaylistItems(constVideos);
      setTitle(playlistTitle);
      setShowCount(true);
      setLoading(false);
    } else if (type === "upNext") {
      setPlaylistItems(constVideos);
      setTitle("Up Next");
      setShowCount(true);
      setLoading(false);
    } else if (type === "recommended") {
      const getRecommended = async () => {
        const response = await videoService.getRecommended(video.id);
        setPlaylistItems([video, ...response]);
        setShowCount(false);
        setLoading(false);
        setTitle("Recommended");
      };
      getRecommended();
    }
  }, []);

  useEffect(() => {
    if (showCount) {
      const index =
        playlistItems.findIndex((item) => item.id === video?.id) + 1;
      setHeaderEnd(
        <>
          <p
            style={{
              display: "inline",
              fontSize: "16px",
              fontWeight: "500",
              color: "#3A3A3C",
            }}
          >
            {index}/{playlistItems.length}
          </p>
        </>,
      );
    }
  }, [showCount, playlistItems, video]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="playlist-preview-component">
      <div className="playlist-header">
        <div className="playlist-header-title">{title}</div>
        <>{headerEnd}</>
      </div>

      <div className="playlist-preview">
        {playlistItems.map((item) => {
          item = item.content ?? item;
          return item.card_type ? (
            <CardPreview
              card={item}
              handleClick={
                type !== "recommended" ? () => navigateToVideo(item) : null
              }
            />
          ) : (
            <ItemCard
              handleClick={
                type !== "recommended" ? () => navigateToVideo(item) : null
              }
              object={item}
              key={item.id}
              is_highlighted={type !== "recommended" && item.id === video.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Playlist;
