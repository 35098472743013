import React, { useEffect, useState, useRef } from "react";
import AssCard from "../../components/Cards/AssCard";
import CardComponent from "../../components/CardComponent/CardComponent";
import { cardService } from "../../services/card.service.ts";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action";
import { cardTypeDict } from "../../utils/constants.js";
import "./styles.scss";

const EditCard = () => {
  const [showImageComponent, setShowImageComponent] = useState(true);
  const [showCodeComponent, setShowCodeComponent] = useState(false);
  const [imageStatus, setImageStatus] = useState("not loaded");
  const [loading, setLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallerMobile, setIsSmallerMobile] = useState(false);
  const [isOneColumn, setIsOneColumn] = useState(false);
  const EditRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleImageFile(files[0]);
    }
  };

  const handleChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      handleImageFile(files[0]);
    }
  };

  const handleImageFile = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setCardInfo((prevCardInfo) => ({ ...prevCardInfo, image: base64String }));
      setImageStatus("loaded");
    };
    reader.readAsDataURL(file);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const fetchCard = async (id) => {
    try {
      const response = await cardService.getCard(id);
      setCardInfo(response);
      setCardInfo((prev) => ({
        ...prev,
        answer1: response.answers[0] || "",
        answer2: response.answers[1] || "",
        answer3: response.answers[2] || "",
        answer4: response.answers[3] || "",
        card_type: cardTypeDict[response.card_type],
      }));

      if (response.image) {
        setImageStatus("loaded");
      }
      if (response.code_string) {
        setShowCodeComponent(true);
      }
    } catch (error) {
      console.error("Error fetching card:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (
      cardInfo.title.trim() === "" ||
      (cardInfo.card_type === "Multiple Choice" &&
        cardInfo.answer1.trim() === "") ||
      (cardInfo.card_type === "Multiple Choice" &&
        cardInfo.answer2.trim() === "")
    ) {
      dispatch(notifyError("Please fill in all required fields!"));
      return;
    }

    const answers = [
      cardInfo.answer1,
      cardInfo.answer2,
      cardInfo.answer3,
      cardInfo.answer4,
    ];
    const filteredAnswers = answers.filter(
      (answer) => answer !== null && answer.trim() !== "",
    );

    if (filteredAnswers.length !== new Set(filteredAnswers).size) {
      dispatch(notifyError("Please ensure all answers are unique!"));
      return;
    }

    const cardData = {
      title: cardInfo.title,
      description: isJsonString(cardInfo.description)
        ? cardInfo.description
        : JSON.stringify(cardInfo.description),
      hint: cardInfo.hint,
      correct_answer: cardInfo.correctAnswer,
      answers: [
        cardInfo.answer1,
        cardInfo.answer2,
        cardInfo.answer3,
        cardInfo.answer4,
      ].filter((answer) => answer?.trim() !== ""),
      explanation: cardInfo.explanation,
      card_type: cardTypeDict[cardInfo.card_type],
      visibility: cardInfo.visibility,
      image_url: showImageComponent ? cardInfo.image : null,
      color: cardInfo.color,
      code_string: showCodeComponent ? cardInfo.code_string : null,
      language: cardInfo.language,
    };

    try {
      await cardService.editCard(location.pathname.split("/").pop(), cardData);
      dispatch(notifySuccess("Card updated successfully!"));
    } catch (error) {
      console.error("Failed to create card:", error);
    }
    navigate("/dashboard?tab=Cards");
  };

  useEffect(() => {
    if (location) {
      fetchCard(location.pathname.split("/").pop());
    }
  }, [location]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        setIsMobile(width < 650);
        setIsSmallerMobile(width < 450);
        setIsOneColumn(
          ((window.innerHeight - 330) * 9) / 16 + 80 >
            0.75 * window.innerWidth - 300,
        );
      }
    });

    if (EditRef.current) {
      resizeObserver.observe(EditRef.current);
    }

    return () => {
      if (EditRef.current) {
        resizeObserver.unobserve(EditRef.current);
      }
    };
  }, []);

  if (loading) {
    return (
      <div className="spinner-container-feed">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="edit-card-page">
      <div className="edit-card-fields" ref={EditRef}>
        <AssCard
          cardInfo={cardInfo}
          setCardInfo={setCardInfo}
          showImageComponent={showImageComponent}
          setShowImageComponent={setShowImageComponent}
          showCodeComponent={showCodeComponent}
          setShowCodeComponent={setShowCodeComponent}
          imageStatus={imageStatus}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          fileInputRef={fileInputRef}
          handleChange={handleChange}
          handleUploadClick={handleUploadClick}
          isMobile={isMobile}
          isSmallerMobile={isSmallerMobile}
        />
        <div className="buttons">
          <ButtonComponent
            icon={false}
            text="Cancel"
            level={"secondary"}
            handleClick={() => navigate(-1)}
          />
          <ButtonComponent icon={false} text="Save" handleClick={handleSave} />
        </div>
      </div>
      {!isOneColumn && (
        <div className="edit-card-preview">
          <div className="title">Card Preview</div>
          <div className="edit-preview">
            <CardComponent
              cardInfo={cardInfo}
              answer1={cardInfo.answer1}
              answer2={cardInfo.answer2}
              answer3={cardInfo.answer3}
              answer4={cardInfo.answer4}
              showCodeComponent={false}
              showImageComponent={showImageComponent}
              image={cardInfo.image}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCard;
