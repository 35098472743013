import React, { useEffect, useRef, useState, useCallback } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import Popup from "../../containers/Popup/Popup.js";
import CrossSign from "../../assets/cross-sign.svg";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import CardComponent from "../../components/CardComponent/CardComponent";
import ColorComponent from "../../components/ColorComponent/ColorComponent";
import { cardService } from "../../services/card.service.ts";
import "./styles.scss";
import { notifyError, notifySuccess } from "../../actions/global.action";
import { useDispatch } from "react-redux";
import AlertModal from "../AlertModal/AlertModal";
import AssCard from "../../components/Cards/AssCard";
import { cardTypeDict } from "../../utils/constants.js";

const UploadAssCard = ({ handleClose }) => {
  const uploadRef = useRef(null);
  const uploadBodyLeftRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallerMobile, setIsSmallerMobile] = useState(false);
  const [isOneColumn, setIsOneColumn] = useState(false);
  useOutsideClick(uploadRef, () => setShowDangerModal(true));
  const [cardInfo, setCardInfo] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    image: null,
    card_type: "Multiple Choice",
    visibility: "public",
    explanation: "",
    description: "",
    title: "",
    color: "#58A5F8",
    code: null,
    language: "Python",
    hint: null,
    correctAnswer: 0,
    code_string: "",
  });
  const [showImageComponent, setShowImageComponent] = useState(true);
  const [showCodeComponent, setShowCodeComponent] = useState(false);
  const [imageStatus, setImageStatus] = useState("not loaded");
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [showDangerModal, setShowDangerModal] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleResize = useCallback(() => {
    if (uploadBodyLeftRef.current) {
      const { width } = uploadBodyLeftRef.current.getBoundingClientRect();
      setIsMobile(width < 650);
      setIsSmallerMobile(width < 450);
      setIsOneColumn(
        ((window.innerHeight - 330) * 9) / 16 + 80 >
          0.75 * window.innerWidth - 300,
      );
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(handleResize);
    });

    if (uploadBodyLeftRef.current) {
      resizeObserver.observe(uploadBodyLeftRef.current);
    }

    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize]);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleImageFile(files[0]);
    }
  };

  const handleChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      handleImageFile(files[0]);
    }
  };

  const handleImageFile = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setCardInfo((prevCardInfo) => ({ ...prevCardInfo, image: base64String }));
      setImageStatus("loaded");
    };
    reader.readAsDataURL(file);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (showDangerModal) {
      if (
        cardInfo.title.trim() === "" &&
        cardInfo.answer1.trim() === "" &&
        cardInfo.answer2.trim() === ""
      ) {
        handleClose();
        setShowDangerModal(false);
      }
    }
  }, [showDangerModal]);

  const handleSave = async () => {
    if (
      cardInfo.title.trim() === "" ||
      (cardInfo.answer1.trim() === "" &&
        cardInfo.card_type === "Multiple Choice") ||
      (cardInfo.answer2.trim() === "" &&
        cardInfo.card_type === "Multiple Choice")
    ) {
      dispatch(notifyError("Please fill in all required fields!"));
      return;
    }

    const answers = [
      cardInfo.answer1,
      cardInfo.answer2,
      cardInfo.answer3,
      cardInfo.answer4,
    ];
    const filteredAnswers = answers.filter(
      (answer) => answer !== null && answer.trim() !== "",
    );

    if (filteredAnswers.length !== new Set(filteredAnswers).size) {
      dispatch(notifyError("Please ensure all answers are unique!"));
      return;
    }

    const cardData = {
      title: cardInfo.title,
      description: JSON.stringify(cardInfo.description),
      hint: cardInfo.hint,
      correct_answer: cardInfo.correctAnswer,
      answers: [
        cardInfo.answer1,
        cardInfo.answer2,
        cardInfo.answer3,
        cardInfo.answer4,
      ].filter((answer) => answer?.trim() !== ""),
      explanation: cardInfo.explanation,
      card_type: cardTypeDict[cardInfo.card_type],
      visibility: cardInfo.visibility,
      image_url: showImageComponent ? cardInfo.image : null,
      color: cardInfo.color,
      code_string: showCodeComponent ? cardInfo.code_string : null,
      language: cardInfo.language,
    };

    try {
      await cardService.createCard(cardData);
      dispatch(notifySuccess("Card created successfully!"));
      handleClose();
    } catch (error) {
      console.error("Failed to create card:", error);
    }
  };

  return (
    <Popup
      className="upload-ass-card-container"
      closePopup={null}
      closeFunc={() => setShowDangerModal(true)}
      withContainer={true}
    >
      <div className="upload-ass-card popup" ref={uploadRef}>
        {showDangerModal && (
          <AlertModal
            type="warning"
            onContinue={() => {
              handleClose();
            }}
            onCancel={() => {
              setShowDangerModal(false);
            }}
            title="Cancel Creating Card"
            message={`Are you sure you want to stop creating a card? This action is irreversible.`}
          />
        )}
        <div className="upload-header">
          Add Card
          <button
            className="upload-cross-sign"
            onClick={() => setShowDangerModal(true)}
          >
            <img src={CrossSign} alt="X" />
          </button>
        </div>

        <div className={`upload-body ${isOneColumn ? "vertical" : ""}`}>
          <div className="upload-body-left" ref={uploadBodyLeftRef}>
            <AssCard
              isMobile={isMobile}
              setCardInfo={setCardInfo}
              cardInfo={cardInfo}
              showImageComponent={showImageComponent}
              setShowImageComponent={setShowImageComponent}
              showCodeComponent={showCodeComponent}
              setShowCodeComponent={setShowCodeComponent}
              fileInputRef={fileInputRef}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              handleChange={handleChange}
              imageStatus={imageStatus}
              handleUploadClick={handleUploadClick}
              isSmallerMobile={isSmallerMobile}
            />
          </div>
          <div className="upload-body-right">
            <div className="title">Card Preview</div>
            <div className="preview-and-colors">
              <div className="card-container-wrapper">
                <CardComponent
                  cardInfo={cardInfo}
                  answer1={cardInfo.answer1}
                  answer2={cardInfo.answer2}
                  answer3={cardInfo.answer3}
                  answer4={cardInfo.answer4}
                  showCodeComponent={false}
                  showImageComponent={showImageComponent}
                  image={cardInfo.image}
                />
              </div>
              {!isOneColumn && cardInfo.card_type !== "Informational" && (
                <div className="colors">
                  <ColorComponent
                    color="#3B3B3B"
                    chosen={cardInfo.color === "#3B3B3B"}
                    setChosen={() => {
                      setCardInfo((prevCardInfo) => ({
                        ...prevCardInfo,
                        color: "#3B3B3B",
                      }));
                    }}
                  />
                  <ColorComponent
                    color="#FF0000"
                    chosen={cardInfo.color === "#FF0000"}
                    setChosen={() => {
                      setCardInfo((prevCardInfo) => ({
                        ...prevCardInfo,
                        color: "#FF0000",
                      }));
                    }}
                  />
                  <ColorComponent
                    color="#006400"
                    chosen={cardInfo.color === "#006400"}
                    setChosen={() => {
                      setCardInfo((prevCardInfo) => ({
                        ...prevCardInfo,
                        color: "#006400",
                      }));
                    }}
                  />
                  <ColorComponent
                    color="#FFA500"
                    chosen={cardInfo.color === "#FFA500"}
                    setChosen={() => {
                      setCardInfo((prevCardInfo) => ({
                        ...prevCardInfo,
                        color: "#FFA500",
                      }));
                    }}
                  />
                  <ColorComponent
                    color="#58A5F8"
                    chosen={cardInfo.color === "#58A5F8"}
                    setChosen={() => {
                      setCardInfo((prevCardInfo) => ({
                        ...prevCardInfo,
                        color: "#58A5F8",
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="upload-footer">
          <div className="upload-footer-buttons">
            <ButtonComponent
              text="Cancel"
              icon={false}
              level="secondary"
              handleClick={() => setShowDangerModal(true)}
            />
            <ButtonComponent
              text="Save"
              icon={false}
              handleClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default UploadAssCard;
