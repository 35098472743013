import { ApiService } from "./api.service.ts";

class CardService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getCard(cardId: string): Promise<any> {
    return await ApiService.get(`/cards/${cardId}/`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createCard(data: any): Promise<any> {
    return await ApiService.post("/cards/create_card", data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async editCard(cardId: string, data: any): Promise<any> {
    return await ApiService.post(`/cards/${cardId}/edit`, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deleteCard(cardId: string): Promise<any> {
    return await ApiService.delete(`/cards/${cardId}/delete`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async viewCard(cardId: string): Promise<any> {
    return await ApiService.post(`/cards/${cardId}/view`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addLike(id: string): Promise<any> {
    return await ApiService.post(`/card/like/${id}/`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async removeLike(id: string): Promise<any> {
    return await ApiService.post(`/card/unlike/${id}/`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getUserCards(): Promise<any> {
    return await ApiService.get(`/user/cards`);
  }

  async changeCardVisibility(cardId: string, visibility: string) {
    return await ApiService.post(`/card/change-visibility/${cardId}/`, {
      visibility,
    });
  }

  async updateCardViews(cardId: string) {
    return await ApiService.post(`/card/update-views/${cardId}/`);
  }
}

export const cardService = new CardService();
