import React, { useState, useEffect } from "react";
import AuthorInfo from "../AuthorInfo/AuthorInfo";
import Video from "../Video/Video";
import crossSign from "../../assets/cross-sign.svg";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent";
import DescriptionTag from "../DescriptionTag/DescriptionTag";
import Playlist from "../Playlist/Playlist";
import Sidebar from "../Sidebar/Sidebar";
import "./styles.scss";
import Spinner from "../Spinner/Spinner";
import { showSignup } from "../../actions/global.action";
import { videoService } from "../../services/video.service.ts";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CardComponent from "../CardComponent/CardComponent";
import { cardData } from "./constants.js";
import { cardService } from "../../services/card.service.ts";

const FeedCard = ({
  type,
  isDetailMode,
  isMyTab,
  setIsDetailMode,
  video,
  selectedOption,
  setSelectedOption,
  navigateToVideo,
  data,
  playlistTitle,
  deviceType,
}) => {
  /*
  Feed Card is a component used to display a video card in the feed.
  It handles all the logic and UI. It includes both usual and detailed modes.

  Props: 
  - isDetailMode: boolean that indicates if the card is in detailed mode
  - isMyTab: boolean that indicates if the card is in My Tab
  - setIsDetailMode: callback prop to set the detailed mode
  - video: object that contains all the information about the video
  - selectedOption: string that indicates the selected option in the sidebar
  - setSelectedOption: callback prop to set the selected option

  Possible cases for the bottom of the detail mode:
  - notes: RichTextEditorComponent
  - playlist/upNext/recs: Playlist
  - description: empty block (default case)
   */

  const [sideContent, setSideContent] = useState(<div></div>);
  const isLandscape = video?.video_type === "landscape" ? true : false;
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const [animationClass, setAnimationClass] = useState("");
  const [allowRender, setAllowRender] = useState(true);
  const [allowClosingRender, setAllowClosingRender] = useState(false);
  const [isSlowClosing, setIsSlowClosing] = useState(false);

  const [isLiked, setIsLiked] = useState(video.is_liked);
  const [likeCount, setlikeCount] = useState(video.likes_count);
  const [cardLikeCount, setCardLikeCount] = useState(video.likes);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userFromState = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (isDetailMode) {
      setAllowClosingRender(true);
      setAnimationClass("opening");
      setAllowRender(true);
      setTimeout(() => {
        setAnimationClass("normal");
        setIsLoading(false);
      }, 300);
    } else {
      setIsSlowClosing(true);
      setAnimationClass("closing");
      setTimeout(() => {
        setAnimationClass("normal");
        setAllowRender(false);
        setIsSlowClosing(false);
        setAllowClosingRender(false);
        setIsLoading(false);
      }, 300);
    }
  }, [isDetailMode]);

  const handleLike = () => {
    if (!isAuthenticated) {
      dispatch(showSignup(true));
      return;
    }

    const newLikeCount = isLiked ? likeCount - 1 : likeCount + 1;
    const newCardLikeCount = isLiked ? cardLikeCount - 1 : cardLikeCount + 1;
    setlikeCount(newLikeCount);
    setCardLikeCount(newCardLikeCount);
    setIsLiked(!isLiked);

    if (isLiked) {
      requestRemoveLike(video.id);
    } else {
      requestAddLike(video.id);
    }
  };

  const requestAddLike = async (videoId) => {
    const service = type === "card" ? cardService : videoService;
    try {
      await service.addLike(videoId);
    } catch (error) {
      console.error(
        `Error adding ${type === "card" ? "card" : "video"} to likes:`,
        error,
      );
    }
  };

  const requestRemoveLike = async (videoId) => {
    const service = type === "card" ? cardService : videoService;
    try {
      await service.removeLike(videoId);
    } catch (error) {
      console.error(
        `Error removing ${type === "card" ? "card" : "video"} from likes:`,
        error,
      );
    }
  };

  useEffect(() => {
    if (type === "card" && video?.id) {
      const timer = setTimeout(() => {
        cardService.updateCardViews(video.id);
      }, 10000); // 10 seconds delay

      return () => clearTimeout(timer);
    }
  }, [type, video?.id]);

  useEffect(() => {
    let currentlySelectedOption = selectedOption;
    if (
      selectedOption === "playlist" ||
      selectedOption === "upNext" ||
      selectedOption === "recommended"
    ) {
      let newOption =
        type === "playlist"
          ? "playlist"
          : type === "video"
            ? "upNext"
            : "recommended";
      setSelectedOption(newOption);
      currentlySelectedOption = newOption;
    }

    switch (currentlySelectedOption) {
      case "notes":
        setSideContent(
          <RichTextEditorComponent
            key={video.id}
            object={video}
            type="notes"
            title="Notes"
            placeholder="Add a note..."
          />,
        );
        break;

      case "playlist":
      case "upNext":
      case "recommended":
        setSideContent(
          <Playlist
            key={video?.id}
            type={currentlySelectedOption}
            video={video}
            constVideos={data}
            navigateToVideo={(item) => navigateToVideo(video, item)}
            playlistTitle={playlistTitle}
          />,
        );
        break;

      case "description":
        setSideContent(
          <div className="description-bottom">
            <></>
          </div>,
        );
        break;

      default:
        break;
    }
  }, [selectedOption, video]);

  if (isLoading) {
    return (
      <div className="spinner-container-feed">
        <Spinner />
      </div>
    );
  }

  return (
    !isLoading && (
      <div
        className={`video-card${isMyTab ? " video-card-my-tab" : ""}${isDetailMode || isSlowClosing || allowClosingRender ? ` video-card-focused ${deviceType}` : ""}${type === "video-preview" ? " video-card-preview" : ""} ${deviceType}`}
      >
        <div className={`left-video-part${isDetailMode ? ` left-focus` : ""}`}>
          <>
            {isDetailMode && (
              <div
                className="cancel"
                onClick={() => {
                  setSelectedOption(null);
                  setIsDetailMode(!isDetailMode);
                }}
              >
                <button>
                  <img src={crossSign} />
                </button>
              </div>
            )}
            {isLandscape && type !== "card" && (
              <div className="landscape-sidebar-container">
                <div
                  className={`landscape-video-container ${(isMyTab || type === "video-preview") && !isDetailMode ? "landscape-my-tab" : ""}${isDetailMode || allowClosingRender || deviceType === "mobile" ? " landscape-focus" : ""}`}
                  id={video.id}
                >
                  <div className="landscape-video-top">
                    <Video
                      id={video?.id}
                      video_type={video?.video_type}
                      video_url={video?.video_url}
                      play={true}
                      key={video?.id}
                      setIsVisible={setIsVisible}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      isDetailMode={isDetailMode}
                      setIsDetailMode={setIsDetailMode}
                    />
                  </div>
                  {!isDetailMode &&
                    !allowClosingRender &&
                    deviceType !== "mobile" && (
                      <div className="landscape-video-bottom">
                        <div className="title">{video?.title}</div>
                        <div className="description-tags-container">
                          <DescriptionTag
                            video={video}
                            pressSeeMore={() => {
                              setIsDetailMode(true);
                              setSelectedOption("expanded");
                            }}
                            type={type}
                            rowsDefault={1}
                            isInline={true}
                          />
                        </div>
                        <div className="author-info-container">
                          <AuthorInfo
                            author={video?.author}
                            views={video?.views}
                            date={video?.date}
                            authorFromState={true}
                          />
                        </div>
                      </div>
                    )}
                </div>
                {!(
                  isMyTab ||
                  isDetailMode ||
                  allowClosingRender ||
                  type === "video-preview" ||
                  deviceType === "mobile"
                ) ? (
                  <div className="landscape-sidebar">
                    <Sidebar
                      type={type}
                      video={video}
                      setIsDetailMode={setIsDetailMode}
                      setSelectedOption={setSelectedOption}
                      handleLike={handleLike}
                      isLiked={isLiked}
                      likeCount={likeCount}
                    />
                  </div>
                ) : null}
              </div>
            )}
            {!isLandscape && type !== "card" && (
              <div className="portrait-sidebar-container">
                <div
                  className={`portrait-video-container${isMyTab && !isDetailMode && !allowClosingRender ? " portrait-my-tab" : ""}`}
                  id={video.id}
                >
                  <div
                    className={`portrait-video-left${isDetailMode || allowClosingRender ? " portrait-focus" : ""}`}
                  >
                    <Video
                      id={video?.id}
                      video_type={video?.video_type}
                      video_url={video?.video_url}
                      play={true}
                      key={video?.id}
                      setIsVisible={setIsVisible}
                      setSelectedOption={setSelectedOption}
                      isDetailMode={isDetailMode}
                      setIsDetailMode={setIsDetailMode}
                      poster={video?.thumbnail_url}
                    />
                  </div>

                  {!isDetailMode &&
                    !allowClosingRender &&
                    deviceType !== "mobile" && (
                      <div className="portrait-video-right">
                        <div className="title">{video?.title}</div>
                        <div className="description-tags-container">
                          <DescriptionTag
                            video={video}
                            pressSeeMore={() => {
                              setIsDetailMode(true);
                              setSelectedOption("expanded");
                            }}
                            type={type}
                            rowsDefault={4}
                          />
                        </div>
                        <div className="author-info-container">
                          <AuthorInfo
                            author={video?.author}
                            views={video?.views}
                            date={video?.date}
                          />
                        </div>
                      </div>
                    )}
                </div>

                {!(
                  isMyTab ||
                  isDetailMode ||
                  allowClosingRender ||
                  type === "video-preview" ||
                  deviceType === "mobile"
                ) ? (
                  <div className="portrait-sidebar">
                    <Sidebar
                      type={type}
                      video={video}
                      setIsDetailMode={setIsDetailMode}
                      setSelectedOption={setSelectedOption}
                      handleLike={handleLike}
                      isLiked={isLiked}
                      likeCount={likeCount}
                    />
                  </div>
                ) : null}
              </div>
            )}
            {type === "card" && (
              <div className="portrait-sidebar-container">
                <div
                  className={`portrait-video-container${isMyTab && !isDetailMode && !allowClosingRender ? " portrait-my-tab" : ""}`}
                  id={video.id}
                >
                  <div
                    className={`portrait-video-left${isDetailMode || allowClosingRender ? " portrait-focus" : ""}`}
                  >
                    <CardComponent
                      cardInfo={video}
                      setIsVisible={setIsVisible}
                      answer1={video.answers[0]}
                      answer2={video.answers[1]}
                      answer3={video.answers[2]}
                      answer4={video.answers[3]}
                      isFunctional={true}
                      showCodeComponent={false}
                      showImageComponent={video.image_url ? true : false}
                      isDetailMode={isDetailMode}
                    />
                  </div>

                  {!isDetailMode &&
                    !allowClosingRender &&
                    deviceType !== "mobile" && (
                      <div className="portrait-video-right">
                        <div className="title">{video?.title}</div>
                        <div className="description-tags-container">
                          <DescriptionTag
                            video={cardData[video?.card_type]}
                            pressSeeMore={() => {}}
                            type={type}
                            rowsDefault={4}
                          />
                        </div>
                        <div className="author-info-container">
                          <AuthorInfo
                            author={userFromState}
                            views={video?.views}
                            date={video?.date}
                          />
                        </div>
                      </div>
                    )}
                </div>

                {!(
                  isMyTab ||
                  isDetailMode ||
                  allowClosingRender ||
                  type === "video-preview" ||
                  deviceType === "mobile"
                ) ? (
                  <div className="portrait-sidebar">
                    <Sidebar
                      type={type}
                      video={video}
                      setIsDetailMode={setIsDetailMode}
                      setSelectedOption={setSelectedOption}
                      handleLike={handleLike}
                      isLiked={isLiked}
                      likeCount={cardLikeCount}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </>
        </div>
        {(isDetailMode || isSlowClosing || allowClosingRender) &&
          isVisible &&
          allowRender && (
            <div className={`right-part ${animationClass} ${deviceType}`}>
              <div className="right-part-top">
                <div className="title">{video?.title}</div>

                <div className="description-tags-container">
                  <DescriptionTag
                    video={type === "card" ? cardData[video?.card_type] : video}
                    selectedOption={selectedOption}
                    isDetailMode={true}
                    type={type}
                  />
                </div>
                <div className="author-info-container">
                  <AuthorInfo
                    author={type === "card" ? userFromState : video?.author}
                    views={video?.views}
                    date={video?.date}
                  />
                </div>
                <div className="right-sidebar">
                  <Sidebar
                    type={type}
                    video={video}
                    horizontal={true}
                    selected={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setIsDetailMode={setIsDetailMode}
                    handleLike={handleLike}
                    isLiked={isLiked}
                    likeCount={type !== "card" ? likeCount : cardLikeCount}
                  />
                </div>
                <div className="border-line"></div>
              </div>
              <div className="right-part-bottom">{sideContent}</div>
            </div>
          )}
      </div>
    )
  );
};

export default FeedCard;
