import React from "react";
import DropdownMenuPrivacy from "../../components/DropdownMenuPrivacy/DropdownMenuPrivacy";
import TextInputComponent from "../../components/TextInputComponent/TextInputComponent";
import TextAreaComponent from "../../components/TextAreaComponent/TextAreaComponent";
import "./styles.scss";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent";

const AssCard = ({
  cardInfo,
  setCardInfo,
  showCodeComponent,
  setShowCodeComponent,
  showImageComponent,
  setShowImageComponent,
  imageStatus,
  handleDragOver,
  handleDrop,
  fileInputRef,
  handleChange,
  handleUploadClick,
  isMobile = false,
  isSmallerMobile = false,
}) => {
  return (
    <div className="asscard-container">
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left"></div>
        <div className="card-row-right">
          <div className={`dropdowns ${isSmallerMobile ? "vertical" : ""}`}>
            <div className="dropdown-wrapper">
              <div className="headline">Card Type</div>
              <div className="dropdown-card">
                <DropdownMenuPrivacy
                  category="cards"
                  currentVisibility={cardInfo.card_type}
                  handleVisibilityChange={(newCardType) =>
                    setCardInfo((prevCardInfo) => ({
                      ...prevCardInfo,
                      card_type: newCardType,
                    }))
                  }
                />
              </div>
            </div>
            <div className="dropdown-wrapper">
              <div className="headline">Visibility</div>
              <div className="dropdown-visibility">
                <DropdownMenuPrivacy
                  category="privacy"
                  currentVisibility={cardInfo.visibility}
                  handleVisibilityChange={(newVisibility) =>
                    setCardInfo((prevCardInfo) => ({
                      ...prevCardInfo,
                      visibility: newVisibility,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">Title</div>
          <div className="description">Max 200 characters.</div>
        </div>
        <div className="card-row-right">
          <TextAreaComponent
            label=""
            handleInput={(e) =>
              setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                title: e.target.value,
              }))
            }
            limit={200}
            placeholder="Write a title"
            initialInput={cardInfo.title}
          />
        </div>
      </div>
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">
            {cardInfo.card_type === "Multiple Choice"
              ? "Question"
              : "Description"}
          </div>
          <div className="description">{`Max ${cardInfo.card_type === "Multiple Choice" ? "400" : "1000"} characters.`}</div>
        </div>
        <div className="card-row-right card-editor">
          <RichTextEditorComponent
            object={cardInfo}
            setContent={(newContent) => {
              if (newContent !== cardInfo.description) {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  description: newContent,
                }));
              }
            }}
            type="cards"
            placeholder={`Write a ${cardInfo.card_type === "Multiple Choice" ? "question" : "description"}`}
            limit={cardInfo.card_type === "Multiple Choice" ? 400 : 1000}
          />
        </div>
      </div>
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">Image</div>
          <div className="description">Optinal</div>
        </div>
        <div
          className={`card-row-right`}
        >
          {showCodeComponent && (
            <div>
              <div className="dropdowns">
                <div className="dropdown-wrapper">
                  <div className="headline">Language</div>
                  <div className="dropdown-language">
                    <DropdownMenuPrivacy
                      category="languages"
                      currentVisibility={cardInfo.language}
                      handleVisibilityChange={(newLanguage) =>
                        setCardInfo((prevCardInfo) => ({
                          ...prevCardInfo,
                          language: newLanguage,
                        }))
                      }
                      noPhoto={true}
                    />
                  </div>
                </div>
              </div>
              <TextAreaComponent
                label=""
                placeholder="Type your code here..."
                handleInput={(e) =>
                  setCardInfo((prevCardInfo) => ({
                    ...prevCardInfo,
                    code_string: e.target.value,
                  }))
                }
                initialInput={cardInfo.code_string}
                limit={200}
              />
            </div>
          )}
          {showImageComponent && (
            <div>
              <div
                className="upload-image"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".jpg,.png"
                  style={{ display: "none" }}
                  onChange={handleChange}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />

                {imageStatus === "not loaded" ? (
                  <>
                    <div className="upload-image-title">
                      <a onClick={handleUploadClick} className="upload-button">
                        Click to upload{" "}
                      </a>{" "}
                      or drag and drop
                    </div>
                    <p className="upload-image-instruction">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </>
                ) : (
                  <>
                    <div className="upload-image-title">
                      Image uploaded successfully!
                    </div>
                    <div className="upload-image-title"></div>
                    <p className="upload-image-instruction">
                      <a onClick={handleUploadClick} className="upload-button">
                        Click to reupload{" "}
                      </a>{" "}
                      or drag and drop{" "}
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {cardInfo.card_type === "Multiple Choice" && (
        <div className={`card-row ${isMobile ? "mobile" : ""}`}>
          <div className="card-row-left">
            <div className="headline">Answers</div>
            <div className="description">
              Minimum of 2 options and a maximum of 4 options. Select the
              correct answer. The choices will be shuffled randomly. The maximum
              length of each answer is 30 characters.
            </div>
          </div>
          <div className="card-row-right">
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer1: e.target.value,
                }));
              }}
              limit={30}
              placeholder="Correct Answer"
              initialValue={cardInfo.answer1 ? cardInfo.answer1 : ""}
              check={"on"}
            />
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer2: e.target.value,
                }));
              }}
              placeholder="Answer"
              initialValue={cardInfo.answer2 ? cardInfo.answer2 : ""}
              limit={30}
            />
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer3: e.target.value,
                }));
              }}
              placeholder="Answer (optional)"
              initialValue={cardInfo.answer3 ? cardInfo.answer3 : ""}
              limit={30}
            />
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer4: e.target.value,
                }));
              }}
              placeholder="Answer (optional)"
              initialValue={cardInfo.answer4 ? cardInfo.answer4 : ""}
              limit={30}
            />
          </div>
        </div>
      )}
      {cardInfo.card_type === "Multiple Choice" && (
        <div className={`card-row ${isMobile ? "mobile" : ""}`}>
          <div className="card-row-left">
            <div className="headline">Explanation (Optional)</div>
            <div className="description">Max 200 characters.</div>
          </div>
          <div className="card-row-right">
            <TextAreaComponent
              label=""
              handleInput={(e) =>
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  explanation: e.target.value,
                }))
              }
              limit={200}
              placeholder="Write an explanation"
              initialInput={cardInfo.explanation}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssCard;
