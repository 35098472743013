import React, { useState, useRef, useEffect } from "react";
import FeedCard from "../../components/FeedComponents/FeedCard";
import InfoCard from "../../components/FeedComponents/InfoCard";
import chevronDown from "../../assets/chevron-down.svg";
import chevronUp from "../../assets/chevron-up.svg";
import { useCardObservers } from "../../hooks/useCardObservers";
import { useSelector } from "react-redux";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWindowHeight from "../../hooks/useWindowHeight";
import "./styles.scss";
const FeedContainer = ({
  data,
  type,
  isMyTab,
  isPrivatePage,
  isEmpty,
  isAuthCard,
  playlistTitle,
  cardRefs,
  totalNumOfCards,
  currentVideoIndex = 0,
}) => {
  /*
    Feed container is a component used to display a list of cards to the user.
    It's used to display Feed, Playlist Page, Video Page. Also, it's embedded into My Playlists tab and VideoPreview.
    It's mostly focused on the frontend part of displaying cards correctly.

    Props: 
    - data: array - list of videos to display (can be extanded to store not only videos)
    - type: string - used to determine the type of the page (Feed, Playlist, Video, Card)
    - isMyTab: boolean - used to display a specific style for the My Tab
    - isPrivatePage: boolean - used to display a specific style for the Private Page
    - isEmpty: boolean - used to display a specific style for the Empty Page
    - isAuthCard: boolean - used to display a specific style for the Auth Card
  */
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const showModal = useSelector((state) => state.global.showModal);
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState("description");
  const feedContainerRef = useRef(null);
  const [deviceType, setDeviceType] = useState("desktop");
  const [currentCardIndex, setCurrentCardIndex] = useState(
    currentVideoIndex + 1,
  );

  useCardObservers(cardRefs, setCurrentCardIndex);

  useEffect(() => {
    if (windowWidth > 1440) {
      setDeviceType("desktop");
    } else if (windowWidth > 1280) {
      setDeviceType("macbook-pro");
    } else if (windowWidth > 770) {
      setDeviceType("macbook-air");
    } else {
      setDeviceType("mobile");
    }

    if (windowHeight < 700) {
      setDeviceType("mobile");
    }
  }, [windowWidth, windowHeight]);

  let isThrottled = false;

  const handleChevron = (isUp, coefficient = 1) => {
    if (isThrottled) return;

    const coef = isUp ? -1 * coefficient : 1 * coefficient;
    if (feedContainerRef.current) {
      const newScrollPosition =
        feedContainerRef.current.scrollTop + coef * (window.innerHeight - 74);
      feedContainerRef.current.scrollTo({
        top: newScrollPosition,
        behavior: "smooth",
      });

      isThrottled = true;

      setTimeout(() => {
        isThrottled = false;
      }, 500);
    }
  };

  const navigateToVideo = (video, item) => {
    const newIndex = data.findIndex((dataItem) => dataItem.id === item.id) + 1;
    const nowIndex = data.findIndex((dataItem) => dataItem.id === video.id) + 1;
    handleChevron(false, newIndex - nowIndex);
  };

  const handleGlobalKeyDown = (event) => {
    if (showModal) {
      return;
    }

    if (
      (event.target.classList.contains("tiptap") &&
        event.target.classList.contains("ProseMirror")) ||
      event.target.classList.contains("text-input-component-input")
    ) {
      return;
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();
      handleChevron(false);
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      handleChevron(true);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isDetailMode, showModal]);

  useEffect(() => {
    if (currentVideoIndex) {
      cardRefs.current[currentVideoIndex].current.scrollIntoView();
      setCurrentCardIndex(currentVideoIndex + 1);
    }
  }, [currentVideoIndex]);

  if (isPrivatePage) {
    return (
      <div className="feed-container">
        <div className="feed-container-card">
          <InfoCard type="privatePage" />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div
        ref={feedContainerRef}
        className={`feed-container hidden-scrollbar ${isMyTab ? " my-tab" : ""}${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${data?.length <= 1 ? " one-card" : ""}`}
      >
        <div className="feed-container-card">
          <InfoCard type="noVideos" />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={feedContainerRef}
      className={`feed-container hidden-scrollbar${isMyTab ? " my-tab" : ""}${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${data?.length <= 1 ? " one-card" : ""}${type === "video-preview" ? " video-preview" : ""}`}
    >
      {data?.map((video, index) => (
        <div
          className={`feed-container-card${type === "video-preview" ? " video-preview-card" : ""}`}
          key={video.id}
          ref={cardRefs.current[index]}
        >
          <FeedCard
            type={video?.card_type ? "card" : type}
            isDetailMode={isDetailMode && deviceType !== "mobile"}
            video={video}
            isMyTab={isMyTab}
            setIsDetailMode={setIsDetailMode}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            navigateToVideo={(video, item) => navigateToVideo(video, item)}
            data={data}
            playlistTitle={playlistTitle}
            deviceType={deviceType}
          />
        </div>
      ))}
      {isAuthCard && (
        <div
          className="feed-container-card-end"
          ref={cardRefs.current[data.length]}
        >
          <InfoCard type="auth" />
        </div>
      )}
      <div
        className={`feed-navigation ${isDetailMode && deviceType !== "mobile" ? `focus-mode ${deviceType}` : ""}`}
      >
        <div className={`chevron-container`}>
          {currentCardIndex != 1 && deviceType !== "mobile" ? (
            <button onClick={() => handleChevron(true)}>
              <img src={chevronUp} alt="Scroll Up" />
            </button>
          ) : null}
        </div>
        <div className="chevron-container">
          {currentCardIndex !== totalNumOfCards && deviceType !== "mobile" ? (
            <button onClick={() => handleChevron(false)}>
              <img src={chevronDown} alt="Scroll down" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FeedContainer;
